import React from 'react'
import { bool, shape, string } from 'prop-types'
import { Box, Link, styled } from '@mui/material'
import { addDashesToNumber } from '@helpers/number-helper'

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  padding: '10px 0',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.dark,
  fontSize: '18px',
  fontWeight: 500,
}))

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '20px',
  margin: '0 auto',
  color: theme.palette.secondary.dark,
}))

const Person = styled('span')({
  fontWeight: 700,
})

const Address = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0 5px',
})

const Underline = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: 400,
  textDecoration: 'underline',
}))

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: 400,
}))

const StoreName = styled('span')({
  fontWeight: 600,
})

/**
 * StoreContactInfo displays above Order Summary via CheckoutSticky for desktop
 * and displays above StoreCart for mobile. Prop storeInfo is an object with keys:
 * salesPersonEmail, salesPersonInfo (ie, name of the Sales Associate), storeAddress,
 * storeCartId, storeName, and storePhoneNumber.
 */
const StoreContactInfo = ({ className, hideHeader, storeInfo }) => {
  const { salesPersonEmail = null, salesPersonInfo, storeAddress, storeCartId, storeName, storePhoneNumber } = storeInfo
  const phone = storePhoneNumber ? addDashesToNumber(storePhoneNumber.replaceAll(/[^0-9]+/g, '')) : ''

  return (
    <div className={className}>
      {!hideHeader && <Header>Store Contact Information</Header>}
      <Content>
        <span>
          Store associate <Person>{salesPersonInfo}</Person> assisted you with your store cart experience
        </span>
        <Address>
          <StoreName>{storeName}</StoreName>
          <Underline>{`${storeAddress?.address1} ${storeAddress?.address2 ?? ''}`}</Underline>
          <Underline>
            {storeAddress?.city}, {storeAddress?.state} {storeAddress?.zip}
          </Underline>
        </Address>
        {phone && (
          <div>
            <StyledLink href={`tel:${phone}`} underline="hover">
              {phone}
            </StyledLink>
          </div>
        )}
        {salesPersonEmail && (
          <div>
            <StyledLink href={`mailto:${salesPersonEmail}?subject=Store%20Cart%20#${storeCartId}`} underline="hover">
              {salesPersonEmail}
            </StyledLink>
          </div>
        )}
      </Content>
    </div>
  )
}

StoreContactInfo.propTypes = {
  className: string,
  hideHeader: bool,
  storeInfo: shape({
    salesPersonEmail: string,
    salesPersonInfo: string,
    storeAddress: shape({
      address1: string,
      address2: string,
      city: string,
      state: string,
      zip: string,
    }),
    storeCartId: string,
    storeName: string,
    storeNumber: string,
    storePhoneNumber: string,
  }),
}

export default StoreContactInfo
